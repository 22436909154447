import Layout from "../components/layout"
import React from "react"
import BackMenu from "../components/backMenu"
import BackgroundSlider from 'gatsby-image-background-slider'
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"


const Exhibitions = () => {
  const data = useStaticQuery(graphql`
    {
      GroupExhibitions: allDirectusExhibition(filter: {type: {eq: "Group Exhibition"}}, sort: {fields: year, order: DESC}) {
        edges {
          node {
            year
            name
            place
          }
        }
      }
      SoloExhibitions: allDirectusExhibition(filter: {type: {eq: "Solo Exhibition"}}, sort: {fields: year, order: DESC}) {
        edges {
          node {
            year
            name
            place
          }
        }
      }
      allDirectusExhibitionsSlideshow {
        edges {
          node {
            slide {
              localFile {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
 const slideData = { 'backgrounds': { 'nodes':  data.allDirectusExhibitionsSlideshow.edges.map(node => { 
    return node.node.slide.localFile 
  } 
  ) 
}}

  return (
    <Layout>
      <div className="container mx-auto lg:w-9/12 xl:w-7/12">
        <div className="m-6 ">
        <div className="exhibit-slider">
          <BackgroundSlider style={{position: "relative", width: "100%", height: "24em", "marginTop": "-24em" }} query={slideData} />
        </div>
        </div>
        <div>
          <SEO title={"Exhibitions"} />
          <div className="flex flex-col mx-6 mt-12">
            <div className="font-semibold">Solo Exhibitions</div>
            <table>
              <tbody>
                {data.SoloExhibitions.edges.map(exhibition => (
                  <tr key={exhibition.node.year+exhibition.node.name}>
                  <td className="font-semibold align-top text-right">{exhibition.node.year}</td>
                  <td className="font-regular align-top pl-2"> {exhibition.node.name}, {exhibition.node.place}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col mx-6 mt-12 mb-20">
            <div className="font-semibold">Group Exhibitions</div>
            <table>
              <tbody>
                {data.GroupExhibitions.edges.map(exhibition => (
                  <tr key={exhibition.node.year+exhibition.node.name}>
                  <td className="font-semibold align-top text-right">{exhibition.node.year}</td>
                  <td className="font-regular align-top pl-2"> {exhibition.node.name}, {exhibition.node.place}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <BackMenu/>
      </div>
    </Layout>
  )
}

export default Exhibitions
