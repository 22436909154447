import { Link } from "gatsby"
import BackButton from "./icons/backButton"
import React from "react"


const BackMenu = () => (
  <div className="fixed bottom-0 right-0">
    <div className="flex flex-col">
      <div className="flex flex-row items-center menu-item main">
        <Link className="hidden xs:flex pl-4 text-3xl" to="/">CHRISTOFFER JOERGENSEN</Link>
        <Link className="xs:hidden pl-4 text-3xl" to="/">C. JOERGENSEN</Link>
      </div>
    </div>
  </div>
)

export default BackMenu
